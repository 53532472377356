import { FestivalSalesOffer } from '~/types/festival'

import { VoucherImagesFunction } from '.'

export enum EventsListingRoutes {
  GiftEventsListing = 'gift-events-listing',
  SideEventsListing = 'side-events-listing',
}

type GiftEventsListingBanners = {
  [EventsListingRoutes.GiftEventsListing]: {
    desktop: string
    mobile: string
  }
  [EventsListingRoutes.SideEventsListing]: {
    desktop: string
    mobile: string
  }
}

// /gift-events-listing
export const getVoucherEventsListingBanners: VoucherImagesFunction<GiftEventsListingBanners> = () => ({
  [FestivalSalesOffer.GIFT]: {
    [EventsListingRoutes.GiftEventsListing]: {
      desktop: 'gift-events-listing/banner.png',
      mobile: 'gift-events-listing/banner-mobile.png',
    },
    [EventsListingRoutes.SideEventsListing]: {
      desktop: 'side-events-listing/banner.png',
      mobile: 'side-events-listing/banner-mobile.png',
    },
  },
  [FestivalSalesOffer.WR]: {
    [EventsListingRoutes.GiftEventsListing]: {
      desktop: 'gift-events-listing/WR_banner_desktop-cz.png',
      mobile: 'gift-events-listing/WR_banner_mobile-cz.png',
    },
    [EventsListingRoutes.SideEventsListing]: {
      desktop: 'side-events-listing/banner.png',
      mobile: 'side-events-listing/banner-mobile.png',
    },
  },
})
