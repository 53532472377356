import { getClubConfig } from '~/utils/clubConfig'

import { FestivalCodesEnum } from '~/generated/graphql'
import { gt as GT } from '~/locale'
import { FestivalSalesOffer } from '~/types/festival'

import { getVoucherSuccessScreenCopy } from './success-screen'
import { getVoucherDetailsCopy } from './voucher-details'
import { getVoucherOrderCopy } from './voucher-order'

// ADD next enum for example FestivalCodesEnum.Fdw to updata other festivals translations
const CODES = [FestivalCodesEnum.Rw] as const

type AvailableCodes = (typeof CODES)[number]
type FestivalTranslation<T> = Pick<Record<FestivalCodesEnum, T>, AvailableCodes>
type VoucherFestivalTranslation<T> = Record<FestivalSalesOffer, FestivalTranslation<T>>

// typeguard

function isTranslationKey<T>(code: any): code is keyof FestivalTranslation<T> {
  return CODES.includes(code)
}

export type VoucherFestivalTranslationFunction<T> = (gt: typeof GT) => VoucherFestivalTranslation<T>

type Code = FestivalCodesEnum | string | null | undefined

function getFestivalSalesOfferCopy<T>(getTranslationObject: VoucherFestivalTranslationFunction<T>) {
  return (code: Code, gt: typeof GT) => {
    const translation = getTranslationObject(gt)[getClubConfig().festivalSalesOffer]

    if (isTranslationKey(code)) return translation[code]

    return translation[FestivalCodesEnum.Rw]
  }
}

export const voucherTranslation = {
  successScreen: getFestivalSalesOfferCopy(getVoucherSuccessScreenCopy),
  details: getFestivalSalesOfferCopy(getVoucherDetailsCopy),
  order: getFestivalSalesOfferCopy(getVoucherOrderCopy),
}
