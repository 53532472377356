import { processEnv } from '~/utils/env'

import { StatusMessages } from '~/components/Checkout/SuccessPage/StatusBox/StatusBoxUtils'
import { voucherImages } from '~/constants/pageImages'
import { voucherTranslation } from '~/constants/pageTranslate'
import { gt as GT } from '~/locale'
import { FestivalSalesOffer } from '~/types/festival'

type Copy = {
  rwReservationStatusMessages: (gt: typeof GT) => StatusMessages
  giftStatusMessages: (gt: typeof GT) => StatusMessages
  voucher: typeof voucherTranslation
}

type Images = {
  voucher: typeof voucherImages
}

export type ClubConfig = {
  aboutPageEnabled: boolean
  redirectHeroToLandingEnabled: boolean
  sideEventListingPageEnabled: boolean
  careerPageEnabled: boolean
  chmEnabled: boolean
  blogEnabled: boolean
  blikEnabled: boolean
  d2dEnabled: boolean
  extraMarketingConsentEnabled: boolean
  footerAwardsEnabled: boolean
  otherFestivalsEnabled: boolean
  restaurantsListingEnabled: boolean
  logoCentered: string
  logoLeft: string
  directCardPaymentEnabled: boolean
  klarnaEnabled: boolean
  paypoEnabled: boolean
  showFooterCopyright: boolean
  useRWInsteadOfRC: boolean
  copy: Copy
  images: Images
  visaMobileEnabled: boolean
  festivalSalesOffer: FestivalSalesOffer
}

export const getClubConfig = () => {
  return CLUB_CONFIG
}

export const redirectIfFeatureDisabledInConfig = {
  notFound: true as const,
}

const rwReservationStatusMessages = (gt: typeof GT) => ({
  PENDING: gt.tp('OrderStatus', 'Processing your reservation...'),
  SUCCEEDED: gt.tp('OrderStatus', 'Booking confirmed!'),
  FAILED: gt.tp('OrderStatus', 'Payment rejected'),
  CANCELED: gt.tp('OrderStatus', 'Reservation cancelled'),
})

const giftStatusMessages = (gt: typeof GT) => ({
  PENDING: gt.tp('OrderStatus', 'Processing your purchase...'),
  SUCCEEDED: gt.tp('OrderStatus', 'Congrats! Your gift purchase is complete!'),
  FAILED: gt.tp('OrderStatus', 'Payment rejected'),
  CANCELED: gt.tp('OrderStatus', 'Gift purchase cancelled'),
})

export const CLUB_CONFIG: ClubConfig = {
  aboutPageEnabled: true,
  careerPageEnabled: true,
  blogEnabled: true,
  chmEnabled: true,
  blikEnabled: true,
  d2dEnabled: true,
  extraMarketingConsentEnabled: true,
  footerAwardsEnabled: true,
  redirectHeroToLandingEnabled: false,
  sideEventListingPageEnabled: true,
  restaurantsListingEnabled: true,
  otherFestivalsEnabled: true,
  logoCentered: '/assets/logo/logo-club.svg',
  logoLeft: '/assets/logo/logo-club.svg',
  directCardPaymentEnabled: true,
  klarnaEnabled: false,
  paypoEnabled: true,
  showFooterCopyright: true,
  useRWInsteadOfRC: false,
  copy: {
    rwReservationStatusMessages: rwReservationStatusMessages,
    giftStatusMessages: giftStatusMessages,
    voucher: voucherTranslation,
  },
  images: {
    voucher: voucherImages,
  },
  visaMobileEnabled: true,
  festivalSalesOffer: FestivalSalesOffer.GIFT,
}

export const isDemo = processEnv.NEXT_ENV === 'demo'
