import { FestivalCodesEnum } from '~/generated/graphql'
import { FestivalSalesOffer } from '~/types/festival'

import { VoucherFestivalTranslationFunction } from '.'

type VoucherOrderCopy = {
  title: string
  section: {
    title: string
    date: string
    guestCount: (peopleCount: number) => string
  }
  summary: {
    title: string
    target: string
    reservationFee: string
    total: string
  }
}

// /voucher-order page
export const getVoucherOrderCopy: VoucherFestivalTranslationFunction<VoucherOrderCopy> = gt => ({
  [FestivalSalesOffer.WR]: {
    [FestivalCodesEnum.Rw]: {
      title: gt.tp('VoucherOrderPage', 'Payment and summary'),
      section: {
        title: gt.tp('VoucherOrderPage', '1. Reservation for a gift 🎁'),
        date: gt.tp('VoucherOrderPage', 'Valid until (date)'),
        guestCount: peopleCount =>
          gt.tnp('VoucherOrderPage', '{{ count }} Guest', '{{ count }} Guests', peopleCount, { count: peopleCount }),
      },
      summary: {
        title: gt.tp('VoucherOrderPage', '2. Summary'),
        target: gt.tp('VoucherOrderPage', 'Reservation for a gift'),
        reservationFee: gt.tp('VoucherOrderPage', 'Reservation fee'),
        total: gt.tp('Checkout', 'Total'),
      },
    },
  },
  [FestivalSalesOffer.GIFT]: {
    [FestivalCodesEnum.Rw]: {
      title: gt.tp('VoucherOrderPage', 'Payment and Summary'),
      section: {
        title: gt.tp('VoucherOrderPage', '1. Reservation for a gift 🎁'),
        date: gt.tp('VoucherOrderPage', 'To be used by'),
        guestCount: peopleCount =>
          gt.tnp('VoucherOrderPage', '{{ count }} Guest', '{{ count }} Guests', peopleCount, { count: peopleCount }),
      },
      summary: {
        title: gt.tp('VoucherOrderPage', '2. Summary'),
        target: gt.tp('VoucherOrderPage', 'Reservation for a gift'),
        reservationFee: gt.tp('VoucherOrderPage', 'Reservation fee'),
        total: gt.tp('Checkout', 'Total'),
      },
    },
  },
})
