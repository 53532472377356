import { FestivalCodesEnum } from '~/generated/graphql'
import { FestivalSalesOffer } from '~/types/festival'

import { VoucherFestivalTranslationFunction } from '.'

type VoucherSuccessScreenCopy = {
  succeeded: string
  congratulations: (festivalName: string) => string
  title: string
  description: string
}

// /sccess screen
export const getVoucherSuccessScreenCopy: VoucherFestivalTranslationFunction<VoucherSuccessScreenCopy> = gt => ({
  [FestivalSalesOffer.WR]: {
    [FestivalCodesEnum.Rw]: {
      succeeded: gt.tp('OrderStatus', 'Booking code reservation confirmed!'),
      congratulations: festival =>
        gt.tp('SuccessPage', 'Congratulations! You have a reservation code for reservation as a gift for {{ festival }}', {
          festival,
        }),
      title: gt.tp('SuccessPage', 'Important'),
      description: gt.tp(
        'SuccessPage',
        'Congratulations! :) We have sent a code to your email address. Just copy the purchased code and apply it to the reservation in the restaurant of your choice.'
      ),
    },
  },
  [FestivalSalesOffer.GIFT]: {
    [FestivalCodesEnum.Rw]: {
      succeeded: gt.tp('OrderStatus', 'Your reservation has been confirmed!'),
      congratulations: festival =>
        gt.tp('SuccessPage', 'Congratulations! You have reservation for a gift for {{ festival }}', {
          festival,
        }),
      title: gt.tp('SuccessPage', 'Read before you visit'),
      description: gt.tp(
        'SuccessPage',
        // eslint-disable-next-line max-len
        'Be eco! Don\'t print the confirmation - at the restaurant, the confirmation on your phone will be enough. Please be on time :). Punctuality gives everyone an equal opportunity to enjoy their dream restaurant experience. After the visit, log in to your account, select "Your reservations" and "Review". The restaurant counts on your honest opinion!'
      ),
    },
  },
})
