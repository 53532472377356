import { getClubConfig } from '~/utils/clubConfig'

import { FestivalSalesOffer } from '~/types/festival'

import { getVoucherEventsListingBanners } from './events-listing'

export type VoucherImagesFunction<T> = () => Record<FestivalSalesOffer, T>

function getVoucherSalesOffer<T>(getImagesObject: VoucherImagesFunction<T>) {
  return () => getImagesObject()[getClubConfig().festivalSalesOffer]
}

export const voucherImages = {
  eventsListing: getVoucherSalesOffer(getVoucherEventsListingBanners),
}
